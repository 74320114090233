import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import OnBoarding from "./onBoarding/OnBoarding";
import Dashboard from "./pages/dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import CompaniesProvider from "./contexts/CompaniesProvider";
import ErrorPage from "./pages/errorPage/ErrorPage";

const App = () => {
   return (
      <CompaniesProvider>
         <main className="App">
            <ToastContainer
               position="top-center"
               autoClose={5000}
               // autoClose={false}
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               closeButton={true}
            />

            <Sidebar />
            <Routes>
               <Route path="/" exact element={<OnBoarding />} />
               <Route path="/dashboard" exact element={<Dashboard />} />
               <Route path="/404" exact element={<ErrorPage />} />
               <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
         </main>
      </CompaniesProvider>
   );
};

export default App;
