import React from "react";
import ReactDOM from "react-dom";
import "./stylesTemplate/global.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
   <BrowserRouter>
      <App />
   </BrowserRouter>,
   document.getElementById("root")
);
