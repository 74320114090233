import React, { useState } from "react";
import styles from "../addCompany.module.scss";
import { v4 as uuid_v4 } from "uuid";
import { IoIosAddCircleOutline as AddIcon } from "react-icons/io";
// import { VscChromeClose as CloseIcon } from "react-icons/vsc";
import { AiTwotoneDelete as DeleteIcon } from "react-icons/ai";
import { AiOutlineArrowLeft as ArrowLeft } from "react-icons/ai";
import { useUpdate } from "../../../../contexts/CompaniesProvider";
import { toast } from "react-toastify";

const OrganizationalChart = ({ prevStep, toggle, resetSteps }) => {
   const [departments, setDepartments] = useState(() => [
      {
         id: uuid_v4(),
         name: "",
         jobs: [
            {
               jobId: uuid_v4(),
               name: "",
               busy: false,
               budgeted: false,
               employeeName: "",
            },
         ],
      },
   ]);

   const update = useUpdate();

   const step1Data = localStorage.getItem("step1");
   const step2Data = localStorage.getItem("step2");
   // console.log(step1Data, step2Data);

   const handleDepartmentName = (e, index) => {
      let newList = [...departments];
      newList[index].name = e.target.value;
      setDepartments(newList);
   };

   const addDepartments = () => {
      let newList = [
         ...departments,
         {
            id: uuid_v4(),
            name: "",
            jobs: [
               {
                  id: uuid_v4(),
                  name: "",
                  busy: false,
                  budgeted: false,
               },
            ],
         },
      ];
      setDepartments(newList);
   };

   const deleteDepartment = elementId => setDepartments(departments.filter(item => item.id !== elementId));

   const handleJobName = (e, departmentIndex, jobIndex) => {
      let newList = [...departments];
      newList[departmentIndex].jobs[jobIndex].name = e.target.value;
      setDepartments(newList);
   };

   const handleBusyJobs = (e, departmentIndex, jobIndex) => {
      let newList = [...departments];
      newList[departmentIndex].jobs[jobIndex].busy = e.target.checked;
      setDepartments(newList);
   };

   const handleEmployeeName = (e, departmentIndex, jobIndex) => {
      let newList = [...departments];
      newList[departmentIndex].jobs[jobIndex].employeeName = e.target.value;
      setDepartments(newList);
   };

   const handleBudgetedJobs = (e, departmentIndex, jobIndex) => {
      let newList = [...departments];
      newList[departmentIndex].jobs[jobIndex].budgeted = e.target.checked;
      setDepartments(newList);
   };

   const addJob = departmentIndex => {
      let departmentsList = [...departments];
      let chosenDepartment = departmentsList[departmentIndex];
      chosenDepartment = {
         ...chosenDepartment,
         jobs: [
            ...chosenDepartment.jobs,
            {
               jobId: uuid_v4(),
               name: "",
               busy: false,
               budgeted: false,
            },
         ],
      };

      departmentsList[departmentIndex] = chosenDepartment;
      setDepartments(departmentsList);
   };

   const deleteJob = (departmentIndex, jobId) => {
      const departmentsCopy = [...departments];
      const newDepartment = departments[departmentIndex].jobs.filter(item => item.jobId !== jobId);
      departmentsCopy[departmentIndex].jobs = newDepartment;
      setDepartments(departmentsCopy);
   };

   const addCompany = (step1Data, step2Data) => {
      const noName = "";
      if (departments.length === 0 || departments === undefined || departments === null) {
         return toast.error("Nu ai adăugat niciun departament");
      } else if (departments.length > 0) {
         departments.map(item => {
            if (item.name === noName) {
               return toast.error("Nu ai adăugat numele departamentului");
            }
            item.jobs.map(el => {
               if (el.name === noName) {
                  return toast.error("Nu ai adăugat numele postului de lucru");
               } else {
                  update(step1Data, step2Data);
                  toggle();
                  resetSteps();
               }
            });
         });
      }
   };

   console.log(departments);

   return (
      <div>
         <h4 className={`mb-4 open-700 primary-green-text`}>Organigramă companie:</h4>

         {departments.length === 0 || departments === null
            ? null
            : departments.map(({ id, name, jobs }, departmentIndex) => (
                 <div key={departmentIndex} className={`mb-5 position-relative`}>
                    <DeleteIcon className={styles.deleteDepartmentIcon} onClick={() => deleteDepartment(id)} />

                    <div className={`mb-4`}>
                       <h5 className={`mb-2 open-700 primary-green-text`}>Departament {departmentIndex + 1}</h5>
                       <h5 className={`mb-0 open-400 primary-green-text`}>Nume departament:</h5>
                       <input
                          type="text"
                          value={name}
                          className={styles.infoInput}
                          placeholder="Departament"
                          onChange={e => handleDepartmentName(e, departmentIndex)}
                       />
                    </div>

                    <h5 className={`mb-3 open-400 primary-green-text`}>Posturi de lucru:</h5>
                    {jobs.length === 0 || jobs === null || jobs === undefined
                       ? null
                       : jobs.map(({ jobId, name, busy, budgeted, employeeName }, index) => (
                            <div key={index} className={`mb-3 ${index !== 0 && "mt-4"}`}>
                               <h6 className={`open-700`}>Post {index + 1}</h6>
                               <div className={`mb-3`}>
                                  <h6>Numele postului:</h6>
                                  <input
                                     type="text"
                                     value={name}
                                     className={styles.infoInput}
                                     placeholder="Nume post"
                                     onChange={e => handleJobName(e, departmentIndex, index)}
                                  />
                               </div>

                               <div className={`mt-3 d-flex align-items-center`}>
                                  <h6 className={`me-4 mb-0`}>Bugetat:</h6>
                                  <div>
                                     <label name="yes" className={`check-label`}>
                                        <input
                                           type="checkbox"
                                           checked={budgeted}
                                           name="yes"
                                           className={`me-2 check-input`}
                                           onChange={e => handleBudgetedJobs(e, departmentIndex, index)}
                                        />
                                        <span className="checkmark item-checkmark"></span>
                                        <span>Da</span>
                                     </label>
                                  </div>
                               </div>

                               <div className={`mt-3 d-flex align-items-center`}>
                                  <h6 className={`me-4 mb-0`}>Disponibilitate:</h6>
                                  <div>
                                     <label name="yes" className={`check-label`}>
                                        <input
                                           type="checkbox"
                                           checked={busy}
                                           name="yes"
                                           className={`me-2 check-input`}
                                           onChange={e => handleBusyJobs(e, departmentIndex, index)}
                                        />
                                        <span className="checkmark item-checkmark"></span>
                                        <span>Ocupat</span>
                                     </label>
                                  </div>
                               </div>

                               {busy && (
                                  <div className={`mt-3 w-100 d-flex align-items-center`}>
                                     <h6 className={`me-3 mb-0`}>Nume angajat:</h6>
                                     <input
                                        type="text"
                                        value={employeeName}
                                        className={styles.employeeInput}
                                        placeholder="Nume persoană"
                                        onChange={e => handleEmployeeName(e, departmentIndex, index)}
                                     />
                                  </div>
                               )}

                               {index !== 0 && (
                                  <div className={`w-100 d-flex justify-content-end`}>
                                     <span
                                        className={`open-400 primary-red-text font-size-12 cursor-pointer`}
                                        onClick={() => deleteJob(departmentIndex, jobId)}
                                     >
                                        Șterge post {index + 1}
                                     </span>
                                  </div>
                               )}
                            </div>
                         ))}
                    <div className={`w-100 d-flex justify-content-center`}>
                       <div
                          className={`d-flex align-items-center cursor-pointer dark-green-text`}
                          onClick={() => addJob(departmentIndex)}
                       >
                          <AddIcon className={styles.addIcon} />
                          <span>Adaugă post</span>
                       </div>
                    </div>
                 </div>
              ))}

         <div className={styles.addDepartment} onClick={addDepartments}>
            <AddIcon className={styles.addIcon} />
            <span className={`text-center`}>Adaugă departament</span>
         </div>

         <div className={`mt-5 ${styles.stepsBtnContainer}`}>
            <button className={`me-4 ${styles.stepButton}`} onClick={prevStep}>
               <span>
                  <ArrowLeft /> Pasul anterior
               </span>
            </button>

            <button className={styles.addCompanyBtn} onClick={() => addCompany(step1Data, step2Data)}>
               Adaugă companie
            </button>
         </div>
      </div>
   );
};

export default OrganizationalChart;
