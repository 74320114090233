import React, { useState } from "react";
import styles from "./onBoarding.module.scss";
import { FaUserAlt as UserIcon, FaLock as PswIcon } from "react-icons/fa";
import { MdMail as MailIcon } from "react-icons/md";
import { AiFillEye as SeePswIcon, AiFillEyeInvisible as HidePswIcon } from "react-icons/ai";
import { toast } from "react-toastify";
import { userNamePattern, emailPattern, passwordPattern } from "../utils/regexPatterns";

const SignUp = ({ changeAuthScreen }) => {
   const [hidePassword, setHidePassword] = useState(() => true);
   const [userNameInput, setUserNameInput] = useState(() => "");
   const [emailInput, setEmailInput] = useState(() => "");
   const [passwordInput, setPasswordInput] = useState(() => "");
   const [repeatPasswordInput, setRepeatPasswordInput] = useState(() => "");

   const validUserName = userNamePattern.test(userNameInput);
   const validEmail = emailPattern.test(emailInput);
   const validPassword = passwordPattern.test(passwordInput);

   const signUpSuccess = (
      <p id="signup-success" className="mb-0">
         Autentificare cu succes!
      </p>
   );
   const allInputsEmpty = (
      <p id="signup-inputs-empty" className="mb-0">
         Toate cîmpurile sunt obligatorii!
      </p>
   );
   const errorUserName = (
      <p id="singup-error-username" className="mb-0">
         Numele de utilizator trebuie să aibă cel puțin 2 și maximum 50 de caractere
      </p>
   );
   const errorEmail = (
      <p id="singup-error-email" className="mb-0">
         Formatul adresei de e-mail nu este valid!
      </p>
   );
   const errorPassword = (
      <p id="singup-error-psw" className="mb-0">
         Parola trebuie să aibă minimum 8 caractere și să conțină cel puțin un număr, cel puțin o literă și cel puțin un
         caracter special
      </p>
   );
   const errorRepeatPassword = (
      <p id="singup-error-r-psw" className="mb-0">
         Parola reintrodusă diferă de cea introdusă inițial!
      </p>
   );

   const toggleViewPassword = () => setHidePassword(!hidePassword);

   const handleUserNameInput = e => setUserNameInput(e.target.value);

   const handleEmailInput = e => setEmailInput(e.target.value);

   const handlePasswordInput = e => setPasswordInput(e.target.value);

   const handleRepeatPasswordInput = e => setRepeatPasswordInput(e.target.value);

   const createAccount = () => {
      if (userNameInput === "" || emailInput === "" || passwordInput === "" || repeatPasswordInput === "") {
         return toast.error(allInputsEmpty);
      }
      if (!validUserName) {
         return toast.error(errorUserName);
      }
      if (!validEmail) {
         return toast.error(errorEmail);
      }
      if (!validPassword) {
         return toast.error(errorPassword);
      }
      if (repeatPasswordInput !== passwordInput) {
         return toast.error(errorRepeatPassword);
      }
      if (validUserName || validEmail || validPassword || validPassword === repeatPasswordInput) {
         return toast.success(signUpSuccess);
      }
   };

   const createAccountOnEnter = e => e.keyCode === 13 && createAccount();

   return (
      <>
         <h3 id="signup-heading" className={`text-center mb-5 font-size-36 open-700`}>
            Creează cont
         </h3>
         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <UserIcon />
            </div>
            <input
               id="signup-username-input"
               type="text"
               value={userNameInput}
               className={styles.authInput}
               placeholder="Nume utilizator"
               onChange={handleUserNameInput}
               onKeyDown={e => createAccountOnEnter(e)}
            />
         </div>

         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <MailIcon />
            </div>
            <input
               id="signup-email-input"
               type="email"
               value={emailInput}
               className={styles.authInput}
               placeholder="E-mail"
               onChange={handleEmailInput}
               onKeyDown={e => createAccountOnEnter(e)}
            />
         </div>

         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <PswIcon />
            </div>
            <input
               id="signup-psw-input"
               type={hidePassword ? "password" : "text"}
               value={passwordInput}
               className={styles.authInput}
               placeholder="Parolă"
               onChange={handlePasswordInput}
               onKeyDown={e => createAccountOnEnter(e)}
            />
            {hidePassword ? (
               <SeePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            ) : (
               <HidePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            )}
         </div>

         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <PswIcon />
            </div>
            <input
               id="signup-repeat-psw-input"
               type={hidePassword ? "password" : "text"}
               value={repeatPasswordInput}
               className={styles.authInput}
               placeholder="Repetă parola"
               onChange={handleRepeatPasswordInput}
               onKeyDown={e => createAccountOnEnter(e)}
            />
            {hidePassword ? (
               <SeePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            ) : (
               <HidePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            )}
         </div>

         <button id="signup-btn" className={styles.authButton} onClick={createAccount}>
            Creează cont
         </button>

         <div className={`mt-5 d-flex align-items-center font-size-20`}>
            <span className={`me-1 open-300`}>Ai deja cont?</span>
            <span
               id="login-span"
               className={`cursor-pointer open-400 primary-green-text`}
               onClick={() => changeAuthScreen("login")}
            >
               Autentifică-te
            </span>
         </div>
      </>
   );
};

export default SignUp;
