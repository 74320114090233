import React, { useState } from "react";
import styles from "./onBoarding.module.scss";
import { MdMail as MailIcon } from "react-icons/md";
import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import { emailPattern } from "../utils/regexPatterns";
import { toast } from "react-toastify";
import { IoReload as ReloadIcon } from "react-icons/io5";

const ForgotPassword = ({ changeAuthScreen }) => {
   const [emailInput, setEmailInput] = useState(() => "");
   const [emailSent, setEmailSent] = useState(() => false);

   const validEmail = emailPattern.test(emailInput);

   const emptyInputNotification = (
      <p id="fp-empty-input" className="mb-0">
         Nu ai introdus nicio adresă de e-mail!
      </p>
   );
   const invalidEmail = (
      <p id="fp-invalid-email" className="mb-0">
         Adresa de e-mail nu are un format valid!
      </p>
   );
   const successNotification = (
      <p id="fp-success-send" className="mb-0">
         E-mail trimis cu succes! Verifică în inbox link-ul de confirmare
      </p>
   );
   const successResend = (
      <p id="fp-success-resend" className="mb-0 resend-message">
         Link-ul de confirmare a fost retrimis
      </p>
   );
   const errorResend = (
      <p id="fp-error-resend" className="mb-0 resend-message">
         A apărut o eroare la retrimiterea link-ului. Încearcă din nou mai târziu
      </p>
   );

   const handleEmailInput = e => setEmailInput(e.target.value);

   const sendEmail = () => {
      if (emailInput === "") {
         return toast.error(emptyInputNotification);
      } else if (!validEmail) {
         return toast.error(invalidEmail);
      } else {
         setEmailSent(true);
         return toast.success(successNotification);
      }
   };

   const sendEmailOnEnter = e => e.keyCode === 13 && sendEmail();

   const resend = () => {
      try {
         toast.success(successResend);
      } catch (err) {
         toast.error(errorResend);
      }
   };

   return (
      <>
         <h4 id="forgot-psw-heading" className={`text-center mb-5`}>
            Îți vom trimite un e-mail cu linkul de confirmare
         </h4>
         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <MailIcon />
            </div>
            <input
               id="fp-email-input"
               value={emailInput}
               type="email"
               className={styles.authInput}
               placeholder="E-mail"
               onChange={handleEmailInput}
               onKeyDown={e => sendEmailOnEnter(e)}
            />
         </div>

         <button id="forgot-psw-send-btn" className={styles.authButton} onClick={sendEmail}>
            Trimite
         </button>

         <div
            id="forgot-psw-back-btn"
            className={`d-flex align-items-center mt-5 open-300 cursor-pointer`}
            onClick={() => changeAuthScreen("login")}
         >
            <BackArrow className={`me-1`} />
            Înapoi
         </div>

         {emailSent && (
            <div
               id="forgot-resend-btn"
               className={`d-flex align-items-center mt-4 open-700 cursor-pointer primary-yellow-text`}
               onClick={resend}
            >
               <ReloadIcon size={25} className={`me-1`} />
               Retrimite link de confirmare
            </div>
         )}
      </>
   );
};

export default ForgotPassword;
