import React, { useState } from "react";
import styles from "../addCompany.module.scss";
import { VscDebugBreakpointLogUnverified as ListPointIcon } from "react-icons/vsc";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import { v4 as uuid_v4 } from "uuid";
import { IoIosAddCircleOutline as AddIcon } from "react-icons/io";
import { AiTwotoneDelete as DeleteIcon } from "react-icons/ai";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft as ArrowLeft } from "react-icons/ai";

const IdentificationData = ({ updateStep2, nextStep, prevStep, nextStepOnEnter }) => {
   const [completeCompanyName, setCompleteCompanyName] = useState(() => "");
   const [headquarters, setHeadquarters] = useState(() => "");
   const [nationality, setNationality] = useState(() => "");
   const [registerCode, setRegisterCode] = useState(() => "");
   const [commerceRegisterCode, setCommerceRegisterCode] = useState(() => "");
   const [specialRegisterCode, setSpecialRegisterCode] = useState(() => "");
   const [ibans, setIbans] = useState(() => [
      {
         id: uuid_v4(),
         iban: "",
      },
   ]);
   const [phoneNumbers, setPhoneNumbers] = useState(() => [
      {
         id: uuid_v4(),
         phoneInput: "",
         contactPerson: "",
      },
   ]);
   const [emails, setEmails] = useState(() => [
      {
         id: uuid_v4(),
         email: "",
      },
   ]);
   const [websites, setWebsites] = useState(() => [
      {
         id: uuid_v4(),
         website: "",
      },
   ]);

   const handleCompleteCompanyName = e => {
      setCompleteCompanyName(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleHeadquarters = e => {
      setHeadquarters(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleNationality = e => {
      setNationality(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleRegisterCode = e => {
      setRegisterCode(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleCommerceRegisterCode = e => {
      setCommerceRegisterCode(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleSpecialRegisterCode = e => {
      setSpecialRegisterCode(e.target.value);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleIban = (e, ibanIndex) => {
      let newList = [...ibans];
      ibans[ibanIndex].iban = e.target.value;
      setIbans(newList);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const addIbanAccount = () => {
      let newList = [...ibans, { id: uuid_v4(), iban: "" }];
      setIbans(newList);
   };

   const deleteIban = elementId => setIbans(ibans.filter(item => item.id !== elementId));

   const handlePhoneValue = (phoneNumber, phoneIndex) => {
      let newPhonesList = [...phoneNumbers];
      newPhonesList[phoneIndex].phoneInput = phoneNumber;
      setPhoneNumbers(newPhonesList);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const handleContactPerson = (e, phoneIndex) => {
      let newPhonesList = [...phoneNumbers];
      newPhonesList[phoneIndex].contactPerson = e.target.value;
      setPhoneNumbers(newPhonesList);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const addPhoneNumber = () => {
      let newPhonesList = [...phoneNumbers, { id: uuid_v4(), phoneInput: "", contactPerson: "" }];
      setPhoneNumbers(newPhonesList);
   };

   const deletePhoneNumber = elementId => setPhoneNumbers(phoneNumbers.filter(item => item.id !== elementId));

   const handleEmail = (e, emailIndex) => {
      let newList = [...emails];
      newList[emailIndex].email = e.target.value;
      setPhoneNumbers(newList);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const addEmailAddress = () => {
      let newList = [...emails, { id: uuid_v4(), email: "" }];
      setEmails(newList);
   };

   const deleteEmailAddress = elementId => setEmails(emails.filter(item => item.id !== elementId));

   const handleWebsite = (e, websiteIndex) => {
      let newList = [...websites];
      newList[websiteIndex].website = e.target.value;
      setWebsites(newList);
      updateStep2({
         step1: false,
         step2: false,
         step3: true,
      });
   };

   const addWebsite = () => {
      let newList = [...websites, { id: uuid_v4(), website: "" }];
      setWebsites(newList);
   };

   const deleteWebsite = elementId => setWebsites(websites.filter(item => item.id !== elementId));

   return (
      <div>
         <div className={`w-100 mb-5 d-flex flex-column`}>
            <h4 className={`open-700 primary-green-text`}>Date de identificare:</h4>

            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Emblema companiei:</h5>
            </div>
            <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
               {({ getRootProps, getInputProps }) => (
                  <section className={styles.upload}>
                     <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={`mb-0 text-center open-300`}>
                           Adaugă imagine prin "drag and drop" sau dă clic pentru a selecta fișierul
                        </p>
                     </div>
                  </section>
               )}
            </Dropzone>
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Denumirea completă a companiei:</h5>
            </div>
            <input
               type="text"
               value={completeCompanyName}
               className={styles.infoInput}
               placeholder="Numele complet al companiei"
               onChange={handleCompleteCompanyName}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Adresa sediului social:</h5>
            </div>
            <input
               type="text"
               value={headquarters}
               className={styles.infoInput}
               placeholder="Sediu social"
               onChange={handleHeadquarters}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Naționalitatea companiei:</h5>
            </div>
            <input
               type="text"
               value={nationality}
               className={styles.infoInput}
               placeholder="Naționalitate"
               onChange={handleNationality}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Codul unic de înregistrare fiscală:</h5>
            </div>
            <input
               type="text"
               value={registerCode}
               className={styles.infoInput}
               placeholder="Cod unic înregistrare"
               onChange={handleRegisterCode}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Numărul de înregistrare în registrul comerțului:</h5>
            </div>
            <input
               type="text"
               value={commerceRegisterCode}
               className={styles.infoInput}
               placeholder="Număr de înregistrare"
               onChange={handleCommerceRegisterCode}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Numărul de înregistrare în registre speciale:</h5>
            </div>
            <input
               type="text"
               value={specialRegisterCode}
               className={styles.infoInput}
               placeholder="Număr de înregistrare"
               onChange={handleSpecialRegisterCode}
               onKeyDown={e => nextStepOnEnter(e)}
            />
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Cont bancar (IBAN):</h5>
            </div>
            {ibans.map(({ id, iban }, index) => (
               <div key={index} className={`w-100 d-flex align-items-center`}>
                  <input
                     key={index}
                     type="text"
                     value={iban}
                     className={styles.infoInput}
                     placeholder={`Cod IBAN ${index + 1}`}
                     onChange={e => handleIban(e, index)}
                     onKeyDown={e => nextStepOnEnter(e)}
                  />
                  {index !== 0 && <DeleteIcon className={styles.deleteIcon} onClick={() => deleteIban(id)} />}
               </div>
            ))}
            <div className={`mt-4 d-flex align-items-center cursor-pointer dark-green-text`} onClick={addIbanAccount}>
               <AddIcon className={styles.addIcon} />
               <span>Adaugă încă un cont bancar</span>
            </div>
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={` mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Număr de telefon:</h5>
            </div>
            {phoneNumbers.map(({ phoneInput, id, contactPerson }, index) => (
               <div
                  key={index}
                  className={`w-100 mb-4 d-flex align-items-center justify-content-between ${styles.phoneContainer}`}
               >
                  <PhoneInput
                     id="phone-input"
                     country={"ro"}
                     key={id}
                     value={phoneInput}
                     onChange={phoneNumber => handlePhoneValue(phoneNumber, index)}
                     onKeyDown={e => nextStepOnEnter(e)}
                  />
                  <div className={`w-100 d-flex align-items-center`}>
                     <input
                        type="text"
                        value={contactPerson}
                        placeholder="Persoană de contact"
                        className={styles.contactPersonInput}
                        onChange={e => handleContactPerson(e, index)}
                        onKeyDown={e => nextStepOnEnter(e)}
                     />
                     {index !== 0 && <DeleteIcon className={styles.deleteIcon} onClick={() => deletePhoneNumber(id)} />}
                  </div>
               </div>
            ))}
            <div className={`d-flex align-items-center cursor-pointer dark-green-text`} onClick={addPhoneNumber}>
               <AddIcon className={styles.addIcon} />
               <span>Adaugă încă un număr</span>
            </div>
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Adresă e-mail:</h5>
            </div>
            {emails.map(({ id, email }, index) => (
               <div key={index} className={`w-100 d-flex align-items-center`}>
                  <input
                     type="email"
                     value={email}
                     className={styles.infoInput}
                     placeholder="E-mail"
                     onChange={e => handleEmail(e, index)}
                     onKeyDown={e => nextStepOnEnter(e)}
                  />
                  {index !== 0 && <DeleteIcon className={styles.deleteIcon} onClick={() => deleteEmailAddress(id)} />}
               </div>
            ))}
            <div className={`mt-4 d-flex align-items-center cursor-pointer dark-green-text`} onClick={addEmailAddress}>
               <AddIcon className={styles.addIcon} />
               <span>Adaugă încă o adresă de e-mail</span>
            </div>
         </div>

         <div className={`w-100 mb-5 d-flex flex-column`}>
            <div className={`w-100 mb-3 ps-5 d-flex align-items-center`}>
               <ListPointIcon className={styles.listIcon} />
               <h5 className={`mb-0 open-400 primary-green-text`}>Website:</h5>
            </div>
            {websites.map(({ id, website }, index) => (
               <div key={index} className={`w-100 d-flex align-items-center`}>
                  <input
                     type="text"
                     value={website}
                     className={styles.infoInput}
                     placeholder="Adresa URL"
                     onChange={e => handleWebsite(e, index)}
                     onKeyDown={e => nextStepOnEnter(e)}
                  />
                  {index !== 0 && <DeleteIcon className={styles.deleteIcon} onClick={() => deleteWebsite(id)} />}
               </div>
            ))}
            <div className={`mt-4 d-flex align-items-center cursor-pointer dark-green-text`} onClick={addWebsite}>
               <AddIcon className={styles.addIcon} />
               <span>Adaugă încă o adresă URL</span>
            </div>
         </div>

         <div className={styles.stepsBtnContainer}>
            <button className={`me-4 ${styles.stepButton}`} onClick={prevStep}>
               <span>
                  <ArrowLeft /> Pasul anterior
               </span>
            </button>

            <button
               className={styles.addCompanyBtn}
               onClick={() =>
                  nextStep({
                     completeCompanyName,
                     headquarters,
                     nationality,
                     registerCode,
                     commerceRegisterCode,
                     specialRegisterCode,
                     ibans,
                     phoneNumbers,
                     emails,
                     websites,
                  })
               }
            >
               <span>
                  Pasul următor <ArrowRight />
               </span>
            </button>
         </div>
      </div>
   );
};

export default IdentificationData;
