import React, { useState } from "react";
import styles from "./onBoarding.module.scss";
import { FaLock as PswIcon } from "react-icons/fa";
import { MdMail as MailIcon } from "react-icons/md";
import { AiFillEye as SeePswIcon, AiFillEyeInvisible as HidePswIcon } from "react-icons/ai";
import { toast } from "react-toastify";
import { emailPattern, passwordPattern } from "../utils/regexPatterns";

const Login = ({ changeAuthScreen }) => {
   const [emailInput, setEmailInput] = useState(() => "");
   const [passwordInput, setPasswordInput] = useState(() => "");
   const [hidePassword, setHidePassword] = useState(() => true);

   const emptyInputs = (
      <p id="login-inputs-empty" className="mb-0">
         Câmpurile trebuie completate pentru a te autentifica
      </p>
   );
   const loginSuccess = (
      <p id="login-success" className="mb-0">
         Autentificare cu succes!
      </p>
   );
   const errorEmail = (
      <p id="login-error-email" className="mb-0">
         Formatul adresei de e-mail nu este valid!
      </p>
   );
   const errorPassword = (
      <p id="login-error-psw" className="mb-0">
         Parola trebuie să aibă minimum 8 caractere și să conțină cel puțin un număr, cel puțin o literă și cel puțin un
         caracter special
      </p>
   );

   const validEmail = emailPattern.test(emailInput);
   const validPassword = passwordPattern.test(passwordInput);

   const handleEmailInput = e => setEmailInput(e.target.value);
   const handlePasswordInput = e => setPasswordInput(e.target.value);
   const toggleViewPassword = () => setHidePassword(!hidePassword);

   const login = () => {
      if (emailInput === "" || passwordInput === "") {
         return toast.error(emptyInputs);
      } else if (!validEmail) {
         return toast.error(errorEmail);
      } else if (!validPassword) {
         return toast.error(errorPassword);
      } else {
         return toast.success(loginSuccess);
      }
   };

   const loginOnEnter = e => e.keyCode === 13 && login();

   return (
      <>
         <h3 id="login-heading" className={`text-center mb-5 font-size-36 open-700`}>
            Autentificare
         </h3>
         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <MailIcon />
            </div>
            <input
               id="login-email-input"
               type="email"
               value={emailInput}
               className={styles.authInput}
               placeholder="E-mail"
               onChange={handleEmailInput}
               onKeyDown={e => loginOnEnter(e)}
            />
         </div>

         <div className={styles.inputContainer}>
            <div className={styles.iconContainer}>
               <PswIcon />
            </div>
            <input
               id="login-password-input"
               value={passwordInput}
               type={hidePassword ? "password" : "text"}
               className={styles.authInput}
               placeholder="Parolă"
               onChange={handlePasswordInput}
               onKeyDown={e => loginOnEnter(e)}
            />
            {hidePassword ? (
               <SeePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            ) : (
               <HidePswIcon className={styles.passwordIcon} onClick={toggleViewPassword} />
            )}
         </div>

         <span
            id="forgot-psw-span"
            className={`open-300 ${styles.forgotPsw}`}
            onClick={() => changeAuthScreen("forgotpsw")}
         >
            Ai uitat parola?
         </span>

         <button id="login-button" type="submit" className={styles.authButton} onClick={login}>
            Autentificare
         </button>

         <span
            id="create-account"
            className={`open-700 mt-5 cursor-pointer font-size-20`}
            onClick={() => changeAuthScreen("signup")}
         >
            Creează cont
         </span>
      </>
   );
};

export default Login;
