import React from "react";
import styles from "./errorPage.module.scss";
import { ReactComponent as ErrorImage } from "../../assets/errorPage/404.svg";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
   const navigate = useNavigate();

   return (
      <div className={`globalPageStyle d-flex flex-column justify-content-center align-items-center`}>
         <ErrorImage className={styles.errorImage} />
         <h2 className={`mt-4 text-center open-700 primary-green-text`}>
            Ne pare rău, dar pagina căutată nu a fost găsită
         </h2>
         <button className={styles.backButton} onClick={() => navigate("/dashboard")}>
            Înapoi la meniul principal
         </button>
      </div>
   );
};

export default ErrorPage;
