import React, { useState } from "react";
import styles from "./onBoarding.module.scss";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

const OnBoarding = () => {
   const [loginShow, setLoginShow] = useState(() => "login");

   const renderAuthScreen = () => {
      if (loginShow === "login") return <Login changeAuthScreen={setLoginShow} />;
      if (loginShow === "signup") return <SignUp changeAuthScreen={setLoginShow} />;
      if (loginShow === "forgotpsw") return <ForgotPassword changeAuthScreen={setLoginShow} />;
   };

   return (
      <div className={styles.onBoardingPage}>
         <div className={styles.authContainer}>{renderAuthScreen()}</div>
      </div>
   );
};

export default OnBoarding;
