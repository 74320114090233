import React, { useState } from "react";
import styles from "./addCompany.module.scss";
import { Modal } from "react-bootstrap";
import IdentificationData from "./steps/IdentificationData";
import OrganizationalChart from "./steps/OrganizationalChart";
import { AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { toast } from "react-toastify";
// import { useUpdateStep1 } from "../../../contexts/CompaniesProvider";

const AddCompanyModal = ({ show, toggle }) => {
   const [steps, setSteps] = useState(() => {
      return {
         step1: true,
         step2: false,
         step3: false,
      };
   });
   const [activeStep, setActiveStep] = useState(() => "step1");
   const [companyName, setCompanyName] = useState(() => "");
   const [incompleteFields, setIncompleteFields] = useState(() => {
      return {
         step1: true,
         step2: true,
         step3: true,
      };
   });
   // step 2
   

   // const updateStep1 = useUpdateStep1();

   const handleCompanyName = e => {
      setCompanyName(e.target.value);
      setIncompleteFields({
         step1: false,
         step2: true,
         step3: true,
      });
   };

   const nextStep = step2Data => {
      if (activeStep === "step1") {
         if (incompleteFields.step1) {
            return toast.error("Câmpul de nume trebuie completat pentru a trece la pasul următor");
         } else {
            setActiveStep("step2");
            setSteps({
               step1: true,
               step2: true,
               step3: false,
            });
            localStorage.setItem("step1", companyName);
            // updateStep1(companyName);
         }
      }
      if (activeStep === "step2") {
         if (incompleteFields.step2) {
            return toast.error("Câmpurile trebuie completate pentru a trece la pasul următor");
         } else {
            setActiveStep("step3");
            setSteps({
               step1: true,
               step2: true,
               step3: true,
            });
            localStorage.setItem("step2", JSON.stringify(step2Data));
         }
      }
   };

   const prevStep = () => {
      if (activeStep === "step3") {
         setActiveStep("step2");
         setSteps({
            step1: true,
            step2: true,
            step3: false,
         });
      }
      if (activeStep === "step2") {
         setActiveStep("step1");
         setSteps({
            step1: true,
            step2: false,
            step3: false,
         });
      }
   };

   const resetSteps = () => {
      setActiveStep("step1");
      setSteps({
         step1: true,
         step2: false,
         step3: false,
      });
   };

   const nextStepOnEnter = e => e.keyCode === 13 && nextStep();

   const closeModal = () => {
      toggle();
      resetSteps();
      setActiveStep("step1");
      setCompanyName("");
   };

   return (
      <Modal size="lg" show={show} onHide={closeModal}>
         <Modal.Header closeButton className={styles.modalHeader}>
            <div className={`w-100 d-flex justify-content-center`}>
               <h2 className={`text-center open-700 dark-green-text`}>Adaugă datele companiei</h2>
            </div>
         </Modal.Header>

         <Modal.Body>
            <div className={`w-100 mb-4 d-flex flex-column`}>
               <div className={styles.stepsContainer}>
                  <div className={styles.axis}></div>
                  <div className={steps.step1 ? styles.activeStep : styles.step}>1</div>
                  <div className={steps.step2 ? styles.activeStep : styles.step}>2</div>
                  <div className={steps.step3 ? styles.activeStep : styles.step}>3</div>
               </div>

               {(() => {
                  if (activeStep === "step1") {
                     return (
                        <>
                           <h4 className={`open-700 primary-green-text`}>Nume (afișat pe platformă):</h4>
                           <input
                              type="text"
                              value={companyName}
                              className={styles.infoInput}
                              placeholder="Nume companie"
                              onChange={handleCompanyName}
                              onKeyDown={e => nextStepOnEnter(e)}
                           />
                        </>
                     );
                  }
                  if (activeStep === "step2") {
                     return (
                        <IdentificationData
                           updateStep2={setIncompleteFields}
                           nextStep={nextStep}
                           prevStep={prevStep}
                           nextStepOnEnter={nextStepOnEnter}
                        />
                     );
                  }
                  if (activeStep === "step3") {
                     return (
                        <OrganizationalChart
                           updateStep3={setIncompleteFields}
                           nextStep={nextStep}
                           prevStep={prevStep}
                           toggle={toggle}
                           resetSteps={resetSteps}
                        />
                     );
                  }
               })()}
            </div>
            {activeStep === "step1" && (
               <div className={`w-100 mt-5 d-flex justify-content-center`}>
                  <button className={styles.addCompanyBtn} onClick={nextStep}>
                     <span>
                        Pasul următor <ArrowRight />
                     </span>
                  </button>
               </div>
            )}
         </Modal.Body>
      </Modal>
   );
};

export default AddCompanyModal;
