import React, { useState } from "react";
import styles from "./dashboard.module.scss";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Animation } from "../../assets/dashboard/nocompanies.svg";
import AddCompanyModal from "../../components/modals/addCompany/AddCompanyModal";

const Dashboard = () => {
   const [showAddCompanyModal, setShowAddCompanyModal] = useState(() => false);
   const companies = [];

   const toggleAddCompanyModal = () => setShowAddCompanyModal(!showAddCompanyModal);

   return (
      <div className={styles.pageStyle}>
         <AddCompanyModal show={showAddCompanyModal} toggle={toggleAddCompanyModal} />

         <div className={`pageNavigation`}>
            <h2>Listă companii</h2>
         </div>

         {companies.length === 0 ? (
            <div className={styles.noContent}>
               <h2 className={`text-center open-700 primary-green-text`}>Nu a fost adăugată nicio companie</h2>
               <Animation alt="companies" />
            </div>
         ) : (
            <div className={`pageLargeContainer`}></div>
         )}

         <OverlayTrigger placement="left" overlay={<Tooltip>Adaugă companie</Tooltip>}>
            <div className={styles.addButton} onClick={toggleAddCompanyModal}>
               <PlusIcon className={styles.plusIcon} />
            </div>
         </OverlayTrigger>
      </div>
   );
};

export default Dashboard;
