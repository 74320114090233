import React, { useState } from "react";
import styles from "./sidebar.module.scss";
import { useLocation } from "react-router-dom";
import { HiMenu as MenuIcon } from "react-icons/hi";
import { VscChromeClose as CloseIcon } from "react-icons/vsc";

const Sidebar = () => {
   const [hideSidebar, setHideSidebar] = useState(() => true);

   const location = useLocation();

   const handleSidebar = () => setHideSidebar(!hideSidebar);

   return (
      <>
         {location.pathname === "/" || location.pathname === "/404" ? null : (
            <>
               <div
                  className={styles.mobileSidebar}
                  style={hideSidebar ? { left: "-1000px" } : { left: "0px", zIndex: "61" }}
               >
                  <CloseIcon className={styles.closeIcon} onClick={handleSidebar} />
                  <div
                     className={location.pathname === "/dashboard" ? styles.itemActive : styles.sidebarItem}
                     onClick={handleSidebar}
                  >
                     <span>Companii</span>
                  </div>
               </div>

               <div className={styles.largeScreenSidebar}>
                  <div className={location.pathname === "/dashboard" ? styles.itemActive : styles.sidebarItem}>
                     <span>Companii</span>
                  </div>
               </div>

               <MenuIcon className={styles.menuIcon} onClick={handleSidebar} />
            </>
         )}
      </>
   );
};

export default Sidebar;
