import React, { useState, useContext } from "react";
import { v4 as uuid_v4 } from "uuid";

const CompaniesContext = React.createContext();
const UpdateContext = React.createContext();

export const useCompanies = () => useContext(CompaniesContext);
export const useUpdate = () => useContext(UpdateContext);

const CompaniesProvider = ({ children }) => {
   const [companies, setCompanies] = useState(() => [
      // {
      //    id: uuid_v4(),
      //    name: "",
      //    logo: "😀",
      //    fullName: "",
      //    headquarters: "",
      //    nationality: "",
      //    registerCode: "",
      //    commerceRegisterCode: "",
      //    specialRegisterCode: "",
      //    ibans: [
      //       {
      //          id: uuid_v4(),
      //          iban: "",
      //       },
      //    ],
      //    phoneNumbers: [
      //       {
      //          id: uuid_v4(),
      //          phoneInput: "",
      //          contactPerson: "",
      //       },
      //    ],
      //    emails: [
      //       {
      //          id: uuid_v4(),
      //          email: "",
      //       },
      //    ],
      //    websites: [
      //       {
      //          id: uuid_v4(),
      //          website: "",
      //       },
      //    ],
      //    departments: [
      //       {
      //          id: uuid_v4(),
      //          name: "",
      //          jobs: [
      //             {
      //                id: uuid_v4(),
      //                name: "",
      //                busy: false,
      //                budgeted: false,
      //             },
      //          ],
      //       },
      //    ],
      // },
   ]);

   const update = (step1Data, step2Data) => {
      console.log(step1Data, step2Data);
   };

   console.log("companies: ", companies);

   return (
      <CompaniesContext.Provider value={companies}>
         <UpdateContext.Provider value={update}>{children}</UpdateContext.Provider>
      </CompaniesContext.Provider>
   );
};

export default CompaniesProvider;
